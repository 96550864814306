<template>
    <div class="pb-20 pt-20">
        <div v-if="!state.statusTrx">
            <div v-if="donations.sender_bank_type == 'virtual_account'">
                <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
                    <div class="bg-white rounded-xl shadow-md p-5">
                        <div class="text-base font-semibold">TRANSFER KE VIRTUAL ACCOUNT</div>
                        <div class="border-2 border-gray-200 mt-3 mb-2"></div>

                        <div class="mb-2">
                            <p class="text-base font-semibold">{{ donations.sender_bank }}</p>
                        </div>
                        <div class="mb-2">
                            <div class="list-group">
                                
                                <label class="mt-2 text-xs">
                                    <i class="fa fa-id-card text-yellow-500" aria-hidden="true"></i> Nomor VA
                                </label>
                                <input type="text"
                                    class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 font-medium text-sm"
                                    placeholder="" v-model="nomorVa" readonly>
                            </div>
                        </div>
                        <div class="mb-2">
                            <div class="list-group">
                                <label class="mt-2 text-xs">
                                    <i class="fa fa-money-bill-wave text-yellow-500" aria-hidden="true"></i> Nominal Transfer
                                </label>
                                <input type="text"
                                    class="appearance-none w-full bg-gray-100 border border-gray-100 rounded h-15 shadow-sm placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 focus:bg-white focus-within:text-gray-600 p-2 font-medium text-sm"
                                    placeholder="" v-model="nominal" readonly>
                            </div>
                        </div>
                    </div>


                        <button @click="cekConfirm" class="mt-6 bg-yellow-500 py-3 rounded-md shadow-md text-base text-white 
                        w-full uppercase font-bold focus:outline-none focus:bg-yellow-600">Cek Status Pembayaran</button>
                </div>

                <CaraBayar 
                :story="donations.sender_bank"
                :va="donations.no_va"
                />
            </div>
            <div v-else>
                <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
                    <div class="bg-white rounded-xl shadow-md my-4 mx-2">
                        <div class="w-full pt-2 md:pt-5 p-4 md:p-3 text-left space-y-2">
                            <p class="custom-font text-sm font-semibold title text-center">E-wallet {{ donations.sender_bank }}</p>
                            <p class="text-sm font-semibold title text-center">Sebesar {{ nominal }}</p>
                            <div class="w-24 bg-white mx-auto my-3" style="height: 2px">&nbsp;</div>
                            <p class="custom-font-2 text-xs font-normal text-center">Silahkan klik lanjut untuk donasi menggunkana e-wallet</p>
                        </div>
                        <div class="my-3 mx-6">
                            <button @click="cekConfirm" class="mt-6 bg-yellow-500 py-3 rounded-md shadow-md text-base text-white 
                            w-full uppercase font-bold focus:outline-none focus:bg-yellow-600">Lanjut</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
                <div class="bg-white rounded-xl shadow-md my-4 mx-2">
                    <div class="">
                        <div class="d-flex flex-row justify-content-center">
                            <img class="d-block mx-auto" width="400" src="@/assets/images/terimakasih.png" />
                        </div>
                        <div class="w-full pt-2 md:pt-5 p-4 md:p-3 text-left space-y-2">
                            <p class="custom-font text-sm font-semibold title text-center">Terimakasih</p>
                            <div class="w-24 bg-white mx-auto my-3" style="height: 2px">&nbsp;</div>
                            <p class="custom-font-2 text-xs font-normal text-center">Jazakumullah khoiron katsiron, semoga Allah Ta’ala memberikan keberkahan kepada para donatur atas donasi yang diberikan.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
            <h1 class="flex-auto text-lg lg:text-xl font-semibold">
                Riwayat Donasi
            </h1>
            <div>
                <div v-if="riwayatdonations.length > 1">
                    <div class="mt-5 grid grid-cols-4 gap-4">

                        <div class="col-span-4" v-for="donation in riwayatdonations" :key="donation.id">
                            <div class="rounded-xl shadow-md bg-white">
                                <figure class="md:flex rounded-xl md:p-0 relative" v-if="donation.id==!donations.id">
                                    <img class="w-full h-34 md:w-48 mx-auto rounded-l-xl object-cover"
                                        :src="donation.campaign.image"
                                        alt="" width="384" height="512">
                                    <div class="w-full pt-6 p-5 md:p-3 text-center md:text-left space-y-4">
                                        <router-link :to="{name: 'campaign.show', params:{slug: donation.campaign.slug}}">
                                            <p class="text-sm font-semibold">
                                                {{ donation.campaign.title }}
                                            </p>
                                        </router-link>
                                        <figcaption class="font-medium">
                                            <p class="text-base text-gray-500">
                                                <span class="font-bold text-yellow-500">Rp. {{ formatPrice(donation.amount) }}</span>
                                            </p>
                                            <p class="text-xs text-gray-500">
                                                <span class="text-gray-500 mr-3 italic">{{ donation.created_at }}</span>
                                            </p>
                                        </figcaption>
                                        <div v-if="donation.status == 'pending'">
                                            <button v-if="donation.sender_bank_type != 'virtual_account'" @click="payment(donation.payment_url)" class="w-full bg-yellow-500 rounded-md shadow-sm text-lg font-bold px-3 py-2 text-white focus:outline-none">BAYAR SEKARANG</button>
                                            <button v-if="donation.sender_bank_type == 'virtual_account'" @click="paymentCara(donation.id)" class="w-full bg-yellow-500 rounded-md shadow-sm text-lg font-bold px-3 py-2 text-white focus:outline-none">BAYAR SEKARANG</button>
                                        </div>
                                    </div>
                                    <div class="ml-auto mr-2 mt-2 absolute left-2">
                                        <div v-if="donation.status == 'success'|| donation.status == 'successful'">
                                            <button class="bg-green-500 border-2 border-green-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Berhasil</button>
                                        </div>
                                        <div v-else-if="donation.status == 'pending'">
                                            <button class="bg-yellow-300 border-2 border-yellow-300 rounded shadow-sm font-bold text-xs py-1 px-2 text-black focus:outline-none">Pending</button>
                                        </div>
                                        <div v-else-if="donation.status == 'expired'|| donation.status == 'cancelled'">
                                            <button class="bg-red-500 border-2 border-red-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Dibatalkan</button>
                                        </div>
                                        <div v-else-if="donation.status == 'failed'">
                                            <button class="bg-red-500 border-2 border-red-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Dibatalkan</button>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>

                    </div>

                    <div class="text-center mt-7" v-show="nextExists">
                        <a @click="loadMore"
                            class="bg-gray-700 text-white p-1 px-3 rounded-md shadow-md focus:outline-none focus:bg-gray-900 cursor-pointer">LIHAT
                            SEMUA <i class="fa fa-long-arrow-alt-right"></i></a>
                    </div>
                
                </div>
                <div v-else>

                    <div class="my-3 bg-red-500 text-white p-4 rounded-md">
                        Anda Belum Memiliki Transaksi Donasi Lain Saat ini!
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // Ganti title
    document.title = "Form Pembayaran";
    
    //hook vue
    import { computed, reactive, onMounted } from 'vue'
    
    //hook vuex
    import { useStore } from 'vuex'
    
    //hook vue router
    import { useRoute, useRouter } from 'vue-router'
    
    //hook Toast
    // import { useToast } from "vue-toastification"
    // import vueMask from 'vue-jquery-mask';

    import CaraBayar from '@/components/campaign/show/CaraBayar.vue'
    // import Amin from './Amin.vue'
    //toast component transaksi
    // import Component from "./Amin.vue";

    export default {
        components: {
            // vueMask,
            CaraBayar,
            // Amin
        },
        computed: {
            isLoggedIn() {
                const store = useStore()
                console.log(store.getters['auth/currentUser'].id)
                return store.getters['auth/isLoggedIn'] == '' ? false : true
            }
        },
        
        methods: {
            selectPaymentMethod(method) {
                this.selectedMethod = method;
            }
        },
        created() {
            window.scrollTo(0, 0)
        },
        setup() {

            //store vuex
            const store = useStore()

            //route
            const route = useRoute()

            //router
            const router = useRouter()

            const state = reactive({
                statusTrx: false
            })
            // const isLoading = reactive({
            //     loading: false              // <-- data email donatur
            // })
            onMounted(() => {
                store.dispatch("donation/getDonationDetail", route.params.id);
                store.dispatch('donation/getDonation');

                const intervalId = setInterval(checkStatus, 1000); // Check every 5 seconds

                // Clear the interval when the component is destroyed
                return () => clearInterval(intervalId);
            })

            const donations = computed(() => {
                return store.state.donation.donationsCara
            })
            const checkStatus = () => {
                store.dispatch('donation/checkDonationStatus', route.params.id)
                    .then(response => {
                        console.log('a', response);
                        if (response.data.data.status === 'successful') {
                            state.statusTrx = true;
                            // router.push({ name: 'ThankYou' });
                        }
                    })
                    .catch(error => {
                        console.log('Error checking donation status:', error);
                });
            };
            const riwayatdonations = computed(() => {
                return store.state.donation.donations
            })
            const nextExists = computed(() => {
                return store.state.donation.nextExists
            })
            const nextPage = computed(() => {
                return store.state.donation.nextPage
            })
            const nomorVa = computed(() => {
                return donations.value.no_va
            })
            const nominal = computed(() => {
                return donations.value.amount
            })
            //toast
            // const toast = useToast()
            //state donation
            const donaturs = reactive({
                name: '',                      // <-- data nama donatur
                phoneNumber: '',               // <-- data email donatur
                email: '',                    // <-- data email donatur
            })
            const payMethode = reactive({
                sender_bank_type: {},
                sender_bank: {}                // <-- data email donatur
            })

            const optionsVuewMask = {
                reverse: true
            }

            //state donation
            const donation = reactive({
                amount: '',                      // <-- data nilai donasi
                pray: '',                       // <-- data kata-kata/doa
                campaignSlug: route.params.slug // <-- data "slug" dari campaign
            })
            function loadMore() {
                store.dispatch('donation/getLoadMore', nextPage.value)
            }
            function cekConfirm() {
                const url = donations.value.payment_url;
                window.open(url, '_blank');
            }

            function setAmount(value) {
                donation.amount = value
            }

            function setOtherAmount() {
                if (donation.amount >= 1/*== 100000 || donation.amount == 250000 || donation.amount == 500000*/) {
                    donation.amount = ''
                }
                document.getElementById('inputAmount').focus()
            }

            function setGoodGuy(e) {
                donaturs.name = e.target.checked ? 'Orang Baik' : ''
            }
            function payment(link) {
                const url = link;
                window.open(url, '_blank');
            }

            function paymentCara(id) {
                // console.log(id);
                router.push({name: 'donation.caradonasi', params:{id: id}}) 
            }

            return {
                optionsVuewMask,
                donation,       // <-- state donation
                donaturs,       // <-- state donaturs
                payMethode,
                cekConfirm,
                setAmount,
                setOtherAmount,
                setGoodGuy,
                donations,
                riwayatdonations,
                nextExists,
                nextPage,
                loadMore,
                payment,
                paymentCara,
                state,
                nomorVa,
                nominal
            }

        }

    }
</script>

<style>

/* Customize the label (the container) */
.container-custom-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-font {
    font-size: 30px;
}
.custom-font-2 {
    font-size: 15px;
}

/* Hide the browser's default checkbox */
.container-custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-custom-checkbox {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-custom-checkbox:hover input ~ .checkmark-custom-checkbox {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-custom-checkbox input:checked ~ .checkmark-custom-checkbox {
  background-color: rgba(245, 158, 11, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-custom-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-custom-checkbox input:checked ~ .checkmark-custom-checkbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-custom-checkbox .checkmark-custom-checkbox:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.list-group-item:hover {
  cursor: pointer;
  background-color: #f8f9fa;
}
</style>