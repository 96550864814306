<template>
    <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
        <div class="bg-white rounded-xl shadow-md p-3">
            <div class="text-base font-semibold">Cara Transfer {{ story }}</div>
            <div class="border-2 border-gray-200 mt-3 mb-2"></div>
            <!-- <div v-if="storyState.isMoreStory">
                <div class="text-sm" v-html="story"></div>
            </div>
            <div v-else>
                <div class="vtl h-80 overflow-hidden">
                    <div class="text-sm" v-html="story"></div>

                    <div class="w-full h-44 absolute -ml-9 bottom-0 fade-in">
                        <button @click="setMoreStory" class="absolute left-1/3 bottom-5 bg-white underline text-yellow-500">
                            Lihat Selengkapnya
                        </button>
                    </div>
                </div>
            </div> -->
            <div v-if="story == 'mandiri'">
                <div class="text-sm">
                    <p><b>Livin’ by Mandiri</b></p>
                    <p>&nbsp;</p>
                    <p>1. Login ke Livin’</p>
                    <p>2. Pilih menu “Bayar”</p>
                    <p>3. Ketik “DOKU VA Aggregator” atau “88899” pada bagian search</p>
                    <p>4. Masukkan No VA “{{ va }}” secara keseluruhan, lalu masukan nominal, kemudian tekan Lanjutkan.</p>
                    <p>5. Pastikan detail pembayaran sudah sesuai seperti no VA, Jumlah Pembayaran dan detail lainnya.</p>
                    <p>6. Pilih Rekening Sumber.</p>
                    <p>7. Pilih Lanjut Bayar kemudian masukkan PIN dan konfirmasi.</p>
                    <p>8. Transaksi selesai dan simpan resi sebagai bukti transaksi.</p>
                    <p>&nbsp;</p>
                </div>
            </div>
            <div v-if="story == 'bsm'">
                <div class="text-sm">
                    <p><b>BSI Mobile</b></p>
                    <p>&nbsp;</p>
                    <p>1. Buka Aplikasi BSI Mobile</p>
                    <p>2. Pilih Menu pembayaran</p>
                    <p>3. Pilih menu E-commerce</p>
                    <p>4. Pilih merchant “DOKU”</p>
                    <p>5. Input nomor virtual account {{ va }}, apabila sudah sesuai tekan Selanjutnya</p>
                    <p>6. Masukkan PIN BSI Mobile</p>
                    <p>7. Muncul detail transaksi “e-commerce” untuk dapat dikonfirmasi oleh Nasabah, jika sudah sesuai Pilih Selanjutnya</p>
                    <p>&nbsp;</p>
                </div>
            </div>
            <div v-if="story == 'bri'">
                <div class="text-sm">
                    <p><b>Mobile Banking BRI</b></p>
                    <p>&nbsp;</p>
                    <p>1. Login aplikasi BRI Mobile</p>
                    <p>2. Pilih menu BRIVA</p>
                    <p>3. Pilih pembayaran baru</p>
                    <p>4. Masukkan kode pembayaran / virtual account number anda: {{ va }}</p>
                    <p>5. Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti Nomor BRIVA, Nama Pelanggan dan Total Pembayaran</p>
                    <p>6. Masukkan PIN</p>
                    <p>7. Simpan notifikasi SMS sebagai bukti pembayaran</p>
                    <p>&nbsp;</p>
                </div>
            </div>         
            <div v-if="story == 'bni'">
                <div class="text-sm">
                    <p><b>Mobile banking payment</b></p>
                    <p>&nbsp;</p>
                    <p>1. Login ke akun BNI Mobile Banking Anda.</p>
                    <p>2. Pilih menu pembayaran, Pilih “Transfer”, Pilih E-Commerce</p>
                    <p>3. Pilih “Virtual Account Billing”, lalu pilih rekening debit Anda, Pilih merchant Foolmoon</p>
                    <p>4. Masukkan Nomor Virtual Account Anda di “Input Baru”</p>
                    <p>5. Masukkan nomor pembayaran {{ va }}</p>
                    <p>6. Konfirmasi bahwa jumlah pembayaran ditampilkan di layar</p>
                    <p>7. Masukkan PIN</p>
                    <p>8. Konfirmasi transaksi dan masukkan kata sandi transaksi Anda</p>
                    <p>9. Transaksi berhasil</p>
                    <p>&nbsp;</p>
                </div>
            </div>  
            <div v-if="story == 'cimb'">
                <div class="text-sm">
                    <p><b>Octo Mobile</b></p>
                    <p>&nbsp;</p>
                    <p>1. Login ke Octo Mobile.</p>
                    <p>2. Pilih menu : Transfer ->  Transfer to Other CIMB Niaga Account</p>
                    <p>3. Pilih Source of fund</p>
                    <p>4. Masukkan nomor virtual account {{ va }}</p>
                    <p>5. Masukkan jumlah pembayaran (Amount) sesuai tagiha</p>
                    <p>6. Klik tombol Next</p>
                    <p>7. Nomor virtual account, nama virtual account dan jumlah pembayaran (Amount) ditampilkan pada layar</p>
                    <p>8. Klik tombol Confirm</p>
                    <p>9. Masukkan Mobile Banking PIN</p>
                    <p>&nbsp;</p>
                </div>
            </div> 
            <div v-if="story == 'permata'">
                <div class="text-sm">
                    <p><b>Permata Mobile X</b></p>
                    <p>&nbsp;</p>
                    <p>1. Buka aplikasi PermataMobile X</p>
                    <p>2. Masukan User ID & Password</p>
                    <p>3. Pilih Pembayaran Tagihan</p>
                    <p>4. Pilih Virtual Account (Akun Virtual)</p>
                    <p>5. Masukkan 16 digit Virtual Account: {{ va }}</p>
                    <p>6. Muncul halaman tujuan pembayaran</p>
                    <p>7. Masukkan nominal pembayaran sesuai dengan yang ditagihkan</p>
                    <p>8. Muncul konfirmasi pembayaran</p>
                    <p>9. Masukkan Kode Otentikasi Transaksi</p>
                    <p>10. Transaksi selesai</p>
                    <p>&nbsp;</p>
                </div>
            </div>      
            <div v-if="story == 'bca'">
                <div class="text-sm">
                    <p><b>MOBILE BANKING - BCA MOBILE (M-BCA)</b></p>
                    <p>&nbsp;</p>
                    <p>1. Lakukan Log in pada aplikasi BCA Mobile</p>
                    <p>2. Pilih m-BCA</p>
                    <p>3. Masukkan kode akses m-BCA</p>
                    <p>4. Masuk ke menu m-Transfer</p>
                    <p>5. Masuklah ke menu Daftar Transfer dan pilih Antar Bank</p>
                    <p>6. Masukkan nomor rekening tujuan {{ va }}, dan pilih DOKU pada kolom Bank</p>
                    <p>7. Setelah itu, nama VA akan muncul, klik Kirim</p>
                    <p>8. Setelah itu kamu diminta memasukkan Personal Identification Number (PIN) BCA, pastikan tidak salah dalam memasukkan PIN</p>
                    <p>9. Masuk ke menu transfer antar bank. Lalu pilih ke Bank DOKU dan rekening yang baru saja didaftarkan, lalu masukkan jumlah uang yang akan kamu transfer sesuai yang tertera di invoice</p>
                    <p>10. Kemudian kamu akan melihat menu konfirmasi nomor rekening penerima. Setelah itu kamu diminta memasukkan Personal Identification Number (PIN) BCA, pastikan tidak salah dalam memasukkan PIN</p>
                    <p>&nbsp;</p>
                </div>
            </div>
            <div v-if="story == 'danamon'">
                <div class="text-sm">
                    <p><b>D-Mobile (M-Banking)</b></p>
                    <p>&nbsp;</p>
                    <p>1. Akses D-Mobile melalui smartphone Anda</p>
                    <p>2. Pilih menu > “Pembayaran” > “Virtual Account”</p>
                    <p>3. Tambahkan biller baru pembayaran</p>
                    <p>4. Masukan 16 digit nomor virtual account ({{ va }})</p>
                    <p>5. Pastikan data pembayaran sudah benar</p>
                    <p>6. Masukkan mPIN Anda untuk konfirmasi</p>
                    <p>7. Transaksi selesai, Anda akan mendapatkan info transaksi</p>
                    <p>&nbsp;</p>
                </div>
            </div>  

            <!-- <div v-else>
            </div> -->
        </div>
    </div>
</template>

<script>

//hook vue
import { reactive } from 'vue'

export default {

    props: {
        story: String,
        va: String
    },
    setup() {

        const storyState = reactive({
            isMoreStory: false
        })

        function setMoreStory() {
            storyState.isMoreStory = true
        }

        return {
            storyState,
            setMoreStory
        }
    }
}
</script>

<style>
iframe {
    width: 100%;
}

/* (A) TIMELINE CONTAINER */
.vtl {
    /* (A1) RELATIVE POSITION REQUIRED TO PROPERLY POSITION THE TIMELINE */
    position: relative;

    /* (A2) RESERVE MORE SPACE TO THE LEFT FOR THE TIMELINE */
    /* padding: 0px 10px 0px 30px; */

    /* (A3) OPTIONAL WIDTH RESTRICTION */
    /* max-width: 400px; */
}

.vtl,
.vtl * {
    *box-sizing: border-box;
}

.fade-in {
    background-image: linear-gradient(#FFFFFF00 30%, #FFF 75%);
}

</style>
