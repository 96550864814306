<template>
    <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
            <h1 class="flex-auto text-lg lg:text-xl font-semibold">
                Riwayat Donasi
            </h1>
            <div v-if="!isLoggedIn">
                <div v-if="donations.length > 0">
                    <div class="mt-5 grid grid-cols-4 gap-4">

                        <div class="col-span-4" v-for="donation in donations" :key="donation.id">
                            <div class="rounded-xl shadow-md bg-white">
                                <figure class="md:flex rounded-xl md:p-0 relative">
                                    <img class="w-full h-34 md:w-48 mx-auto rounded-l-xl object-cover"
                                        :src="donation.campaign.image"
                                        alt="" width="384" height="512">
                                    <div class="w-full pt-6 p-5 md:p-3 text-center md:text-left space-y-4">
                                        <router-link :to="{name: 'campaign.show', params:{slug: donation.campaign.slug}}">
                                            <p class="text-sm font-semibold">
                                                {{ donation.campaign.title }}
                                            </p>
                                        </router-link>
                                        <figcaption class="font-medium">
                                            <p class="text-base text-gray-500">
                                                <span class="font-bold text-yellow-500">Rp. {{ formatPrice(donation.amount) }}</span>
                                            </p>
                                            <p class="text-xs text-gray-500">
                                                <span class="text-gray-500 mr-3 italic">{{ donation.created_at }}</span>
                                            </p>
                                        </figcaption>
                                        <div v-if="donation.status == 'pending'">
                                            <button v-if="donation.sender_bank_type != 'virtual_account'" @click="payment(donation.payment_url)" class="w-full bg-yellow-500 rounded-md shadow-sm text-lg font-bold px-3 py-2 text-white focus:outline-none">BAYAR SEKARANG</button>
                                            <button v-if="donation.sender_bank_type == 'virtual_account'" @click="paymentCara(donation.id)" class="w-full bg-yellow-500 rounded-md shadow-sm text-lg font-bold px-3 py-2 text-white focus:outline-none">BAYAR SEKARANG</button>
                                        </div>
                                    </div>
                                    <div class="ml-auto mr-2 mt-2 absolute left-2">
                                        <div v-if="donation.status == 'success'|| donation.status == 'successful'">
                                            <button class="bg-green-500 border-2 border-green-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Berhasil</button>
                                        </div>
                                        <div v-else-if="donation.status == 'pending'">
                                            <button class="bg-yellow-300 border-2 border-yellow-300 rounded shadow-sm font-bold text-xs py-1 px-2 text-black focus:outline-none">Pending</button>
                                        </div>
                                        <div v-else-if="donation.status == 'expired'|| donation.status == 'cancelled'">
                                            <button class="bg-red-500 border-2 border-red-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Dibatalkan</button>
                                        </div>
                                        <div v-else-if="donation.status == 'failed'">
                                            <button class="bg-red-500 border-2 border-red-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Dibatalkan</button>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>

                    </div>
                    <div class="my-3 bg-red-500 text-white p-4 rounded-md">
                        Riwayat transaksi akan dibersihkan dalam: {{ timeString  }} <br>
                        Silahkan Registrasi/Login dengan email pada saat anda donasi, Terimakasih
                    </div>
                    <div class="text-center mt-7" v-show="nextExists">
                        <a @click="loadMore"
                            class="bg-gray-700 text-white p-1 px-3 rounded-md shadow-md focus:outline-none focus:bg-gray-900 cursor-pointer">LIHAT
                            SEMUA <i class="fa fa-long-arrow-alt-right"></i></a>
                    </div>
                
                </div>
                <div v-else>

                    <div class="my-3 bg-red-500 text-white p-4 rounded-md">
                        Anda Belum Memiliki Transaksi Donasi Saat ini!
                    </div>

                </div>
            </div>
            <div v-else>
                <div v-if="donations.length > 0">
                    <div class="mt-5 grid grid-cols-4 gap-4">

                        <div class="col-span-4" v-for="donation in donations" :key="donation.id">
                            <div class="rounded-xl shadow-md bg-white">
                                <figure class="md:flex rounded-xl md:p-0 relative">
                                    <img class="w-full h-34 md:w-48 mx-auto rounded-l-xl object-cover"
                                        :src="donation.campaign.image"
                                        alt="" width="384" height="512">
                                    <div class="w-full pt-6 p-5 md:p-3 text-center md:text-left space-y-4">
                                        <router-link :to="{name: 'campaign.show', params:{slug: donation.campaign.slug}}">
                                            <p class="text-sm font-semibold">
                                                {{ donation.campaign.title }}
                                            </p>
                                        </router-link>
                                        <figcaption class="font-medium">
                                            <p class="text-base text-gray-500">
                                                <span class="font-bold text-yellow-500">Rp. {{ formatPrice(donation.amount) }}</span>
                                            </p>
                                            <p class="text-xs text-gray-500">
                                                <span class="text-gray-500 mr-3 italic">{{ donation.created_at }}</span>
                                            </p>
                                        </figcaption>
                                        <div v-if="donation.status == 'pending'">
                                            <button v-if="donation.sender_bank_type != 'virtual_account'" @click="payment(donation.payment_url)" class="w-full bg-yellow-500 rounded-md shadow-sm text-lg font-bold px-3 py-2 text-white focus:outline-none">BAYAR SEKARANG</button>
                                            <button v-if="donation.sender_bank_type == 'virtual_account'" @click="paymentCara(donation.id)" class="w-full bg-yellow-500 rounded-md shadow-sm text-lg font-bold px-3 py-2 text-white focus:outline-none">BAYAR SEKARANG</button>
                                        </div>
                                    </div>
                                    <div class="ml-auto mr-2 mt-2 absolute left-2">
                                        <div v-if="donation.status == 'success'|| donation.status == 'successful'">
                                            <button class="bg-green-500 border-2 border-green-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Berhasil</button>
                                        </div>
                                        <div v-else-if="donation.status == 'pending'">
                                            <button class="bg-yellow-300 border-2 border-yellow-300 rounded shadow-sm font-bold text-xs py-1 px-2 text-black focus:outline-none">Pending</button>
                                        </div>
                                        <div v-else-if="donation.status == 'expired'|| donation.status == 'cancelled'">
                                            <button class="bg-red-500 border-2 border-red-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Dibatalkan</button>
                                        </div>
                                        <div v-else-if="donation.status == 'failed'">
                                            <button class="bg-red-500 border-2 border-red-500 rounded shadow-sm font-bold text-xs py-1 px-2 text-white focus:outline-none">Dibatalkan</button>
                                        </div>
                                    </div>
                                </figure>
                            </div>
                        </div>

                    </div>

                    <div class="text-center mt-7" v-show="nextExists">
                        <a @click="loadMore"
                            class="bg-gray-700 text-white p-1 px-3 rounded-md shadow-md focus:outline-none focus:bg-gray-900 cursor-pointer">LIHAT
                            SEMUA <i class="fa fa-long-arrow-alt-right"></i></a>
                    </div>
                
                </div>
                <div v-else>

                    <div class="my-3 bg-red-500 text-white p-4 rounded-md">
                        Anda Belum Memiliki Transaksi Donasi Saat ini!
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    //hook vue
    import { computed, onMounted } from 'vue'

    //hook vuex
    import { useStore } from 'vuex'

    //hook vue router
    import { useRouter } from 'vue-router'

    export default {
        data() {
            return {
            timeLeft: 0, // Waktu tersisa dalam detik
            };
        },
        computed: {
            // Mengonversi waktu tersisa menjadi string menit:detik
            timeString() {
                const minutes = Math.floor(this.timeLeft / 60);
                const seconds = this.timeLeft % 60;
                return `${minutes}:${seconds.toString().padStart(2, '0')}`;
            },
            isLoggedIn() {
                const store = useStore()
                console.log(store.getters['auth/currentUser'].id)
                return store.getters['auth/isLoggedIn'] == '' ? false : true
            }
        },
        mounted() {
            this.startCountdown();
        },
        methods: {
            startCountdown() {

                // Perbarui waktu tersisa setiap detik
                this.updateTimeLeft();
                this.intervalId = setInterval(this.updateTimeLeft, 1000);
                },
                updateTimeLeft() {
                    const now = Date.now();
                    const endTime = parseInt(localStorage.getItem('endTime'), 10);
                    // Hitung waktu tersisa dalam detik
                    this.timeLeft = Math.max(0, Math.round((endTime - now) / 1000));

                    if (this.timeLeft === 0) {
                        clearInterval(this.intervalId);
                        // Opsional: Hapus endTime dari localStorage
                        localStorage.removeItem('email');
                        localStorage.removeItem('endTime');
                        window.location.reload();
                    }
                }
        },
        beforeUnmount() {
            // Bersihkan interval ketika komponen di-unmount
            clearInterval(this.intervalId);
        },
        setup() {

            //store vuex
            const store = useStore()

            //router
            const router = useRouter()

            //onMounted akan menjalankan action "getDonation" di module "donation"
            onMounted(() => {
                store.dispatch('donation/getDonation')
            })

            //digunakan untuk get data state "donations" di module "donation" 
            const donations = computed(() => {
                return store.state.donation.donations
            })
            //digunakan untuk get data state "nextExists" di module "donation" 
            const nextExists = computed(() => {
                return store.state.donation.nextExists
            })

            //digunakan untuk get data state "nextPage" di module "donation" 
            const nextPage = computed(() => {
                return store.state.donation.nextPage
            })

            //loadMore function
            function loadMore() {
                store.dispatch('donation/getLoadMore', nextPage.value)
            }

            //function payment "Midtrans"
            function payment(link) {
                const url = link;
                window.open(url, '_blank');
                // window.snap.pay(snap_token, {

                //     onSuccess: function () {
                //         router.push({name: 'donation.index'})  
                //     },
                //     onPending: function () {
                //         router.push({name: 'donation.index'})
                //     },
                //     onError: function () {
                //         router.push({name: 'donation.index'})  
                //     }
                // })
            }

            function paymentCara(id) {
                router.push({name: 'donation.caradonasi', params:{id: id}}) 
            }

            return {
                donations,      // <-- return donations
                nextExists,     // <-- return nextExists
                nextPage,       // <-- return nextPage
                loadMore,       // <-- return loadMore
                payment,        // <-- return payment Midtrans Snap
                paymentCara,
            }

        }

    }
</script>

<style>

</style>